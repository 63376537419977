import { useEffect, useState, useContext, useMemo } from 'react'
import { Routes, Route, Outlet, Link } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { Library2 } from './library2'
import { Loading } from './loading'
import './home.css'
import $axios from '../../tools/axiosWrapper'
import LoginIcon from '@mui/icons-material/Login'
import InfoIcon from '@mui/icons-material/Info'
import { Stack, Button, Typography } from '@mui/material'
import { motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import { Login } from './login'
import { Banner } from './banner'
import { Master } from './master'

export const Home = ({ user, search, setSearch, results, setResults }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  return (
    <>
      <div className='home' style={{ background: theme.palette.purple[0] }}>
        <Stack
          style={{
            width: '100vw',
            minHeight: '50vh',
          }}
          alignItems={'center'}
        >
          {user == null ? (
            <Loading />
          ) : (
            <>
              <Library2
                user={user}
                search={search}
                setSearch={setSearch}
                results={results}
                setResults={setResults}
              />
            </>
          )}
          {user == false && <Login />}
          <Banner />
        </Stack>
      </div>
    </>
  )
}
